/* eslint-disable no-unreachable */
/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
 * Main application charts
 */
import "./style.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import { Divider, Fab, Grid, Icon, IconButton, Skeleton, Tooltip } from "@mui/material";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { getInterval } from "components/Custom/DatePresets";
import { setFilter, deleteFilter } from "redux-react/reducers/filtersReducers";
import { wsEvent } from "redux-react/actions/ws";
import { socket } from "redux-react/middleware/ws";
import i18n from "i18n";
import lod_ from "lodash";
import { read, utils } from "xlsx";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import DictionaryDataTable from "components/Custom/Tables/DictionaryDataTable";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import MDTypography from "components/Basics/MDTypography";
import CrossTable from "./crossTable";
import ProportionChart from "./proportionChart";
import OneValueChart from "./oneValueChart";
import RuleComponent from "./ruleChart";
import ImportDialog from "./form/import";

/**
 * Edit the order of the charts
 * @param {*} param0
 * @returns
 */
const ChartEditingOrder = ({ getChartsData, type, dragHandler }) => {
	const length = getChartsData(type).length;
	if (length < 2) return null;
	return (
		<MDBox flex="1" mr={2} display="flex" flexDirection="column">
			<MDTypography variant="h6" m={0} p={0}>
				Ordre des graphiques
			</MDTypography>
			<MDTypography variant="caption">Déplacez pour réorganiser</MDTypography>

			<DragDropContext
				onDragEnd={({ destination, source }) => {
					if (!source || !destination) return;
					dragHandler({ type: type, from: source.index, to: destination.index });
				}}
			>
				<Droppable droppableId="dnd-list" direction="vertical">
					{provided => (
						<MDBox {...provided.droppableProps} ref={provided.innerRef}>
							{getChartsData(type).map((chart, key) => {
								let condKey = chart.code;
								return (
									<Draggable key={condKey} index={key} draggableId={condKey}>
										{provided => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<Tooltip
													placement="right"
													title={
														<MDBox style={{ textAlign: "left" }}>
															<MDTypography
																variant="body2"
																fontSize="small"
																color="white"
																fontWeight="bold"
															>
																{chart.display.title}
															</MDTypography>
															<MDTypography
																variant="body2"
																fontSize="small"
																color="white"
																fontWeight="500"
																fontStyle="italic"
															>
																{chart.display.description}
															</MDTypography>
														</MDBox>
													}
												>
													<MDBox
														bgColor="white"
														shadow="xl"
														mt={1}
														p={1}
														borderRadius="md"
														display="flex"
														alignItems="center"
													>
														<Icon sx={{ mr: 2 }}>drag_indicator</Icon>
														<MDTypography variant="body2" fontSize="small">
															{chart.display.title}
														</MDTypography>
													</MDBox>
												</Tooltip>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</MDBox>
					)}
				</Droppable>
			</DragDropContext>
		</MDBox>
	);
};

/**
 * Floating button to edit the page
 * @param {*} param0
 * @returns
 */
const FloatingButton = ({ edit, onClick = () => {} }) => {
	/**
	 * Edition menu for a potential update
	 *
	if (edit) {
		return (
			<MDBox
				style={{
					position: "fixed",
					bottom: 20,
					right: 20,
					zIndex: 1000,
					gap: "1rem"
				}}
				display="flex"
				flexDirection="column"
			>
				<Tooltip title="Ajouter un graphique" placement="left" arrow>
					<Fab size="medium" color="info" onClick={onClick}>
						<Icon fontSize="small">pie_chart</Icon>
					</Fab>
				</Tooltip>
				<Tooltip title="Supprimer la page" placement="left" arrow>
					<Fab size="medium" color="error" onClick={onClick}>
						<Icon fontSize="small">delete</Icon>
					</Fab>
				</Tooltip>
				<Tooltip title="Fermer le menu d'édition" placement="left" arrow>
					<Fab size="medium" color="info" onClick={onClick}>
						<Icon fontSize="small">close</Icon>
					</Fab>
				</Tooltip>
			</MDBox>
		);
	} else {
		return (
			<Tooltip title="Ouvrir le menu d'édition" placement="left" arrow>
				<MDBox
					style={{
						position: "fixed",
						bottom: 20,
						right: 20,
						zIndex: 1000
					}}
				>
					<Fab size="medium" color="info" onClick={onClick}>
						<Icon fontSize="small">edit</Icon>
					</Fab>
				</MDBox>
			</Tooltip>
		);
	}
	*/

	return (
		<Tooltip title="Éditer la page" placement="left" arrow>
			<MDBox
				style={{
					position: "fixed",
					bottom: 20,
					right: 20,
					zIndex: 1000
				}}
			>
				<Fab variant="extended" color="info" onClick={onClick}>
					{!edit ? <Icon>edit</Icon> : <Icon>close</Icon>}
				</Fab>
			</MDBox>
		</Tooltip>
	);
};

/**
 * Default component to display charts, works with dynamic datas
 */
export default function ChartsPage({
	route,
	form,
	insertFormItem: insertFormItemProps,
	updateFormItem: updateFormItemProps,
	deleteFormItem: deleteFormItemProps,
	handleEditChart: handleEditChartProps
}) {
	const UPDATE_PAGE_CHART = `updatePageChart_${route.route}`;

	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);
	const filters = useSelector(state => state.filters);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(true);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Dictionary for dialog
	const [dictionary, setDictionary] = useState({});
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);
	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);
	const [reloadTable, setReloadTable] = useState(false);

	// Datas for dialog
	const [importDialog, setImportDialog] = useState({ open: false });
	// File name when import CSV
	const [fileImportName, setFileImportName] = useState("");

	/**
	 * Page edition
	 */
	const [pageEditMode, setPageEditMode] = useState(false);

	// Insert item in form
	const insertFormItem = () => {
		insertFormItemProps({
			route: route,
			form: form,
			PI: true,
			empty: true,
			selectedId: null,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Update item in form
	const updateFormItem = (items, target) => {
		updateFormItemProps({
			route: route,
			form: form,
			PI: true,
			empty: false,
			selectedId: items._id,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Delete item in form
	const deleteFormItem = (item, target) => {
		deleteFormItemProps({
			form: form,
			target: target,
			item: item,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Handle import CSV
	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();

			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				// Open import modal with data
				setImportDialog({ ...importDialog, open: true, values: data });
			};
			reader.readAsArrayBuffer(file);
			// Set file name
			setFileImportName(file.name);
			// Reset input
			event.target.value = null;
		} catch (error) {
			// Error
		}
	};
	// Import CSV datas in DB
	const handleImportCSV = obj => {
		// Add supplementary datas
		obj.collection = form.collectionDefault;
		obj.assistantID = profile.assistantID;
		obj.fileName = fileImportName;

		const onSuccess = res => {
			dispatch(
				display({
					message: `${res.insertedCount} données importées`,
					type: "success"
				})
			);

			setReloadTable(!reloadTable);
		};

		dispatch(FormActions.importCSV(obj, onSuccess));
	};

	/**
	 * Get charts data to be displayed in front
	 */
	const getChartsData = typeList => {
		const filteredCharts = charts.filter(chart =>
			typeList.includes(chart.customType || chart.type)
		);
		const sortedCharts = filteredCharts.sort((a, b) => a.display.rank - b.display.rank);
		return sortedCharts;
	};
	/* Get filters from back */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/* Build default filters */
	function buildDefaultFilters(defaultFilters) {
		let returnObject = {};

		for (let filter of defaultFilters) {
			// If filter has no default value, continue
			if (!filter.defaultValue) {
				continue;
			}
			// Set default value by type
			switch (filter.type) {
				// Datetime
				case "datetime": {
					// Get value & interval
					let defaultValue = filter.defaultValue;
					// Set datetime filter to store, to display it on the page
					dispatch(
						setFilter({
							assistantID: profile.assistantID,
							page: route.route,
							attribute: filter.attribute,
							type: "datetime",
							mode: "auto",
							code: defaultValue
						})
					);

					let interval = getInterval(defaultValue);
					// Mapped value
					returnObject[filter.attribute] = {
						name: filter.attribute,
						type: filter.type,
						value: interval
					};
					break;
				}
				default:
					break;
			}
		}
		return returnObject;
	}
	/* Load charts from back */
	async function loadCharts(defaultFilters = null, myPageFilters = pageFilters) {
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// If there is default filters, set them (ONLY ON PAGE LOAD)
		if (!lod_.isEmpty(defaultFilters)) {
			Object.keys(actualFilters).map(key => {
				let filter = actualFilters[key];
				let defaultFilter = defaultFilters[key];
				// Replace value if it is empty
				if (lod_.isEmpty(filter?.values)) {
					actualFilters[key] = {
						...defaultFilter
					};
				}
			});
		}

		// Remove unused filters on page, we can have filters in local storage that are not on the page
		Object.keys(actualFilters).map(key => {
			let filter = actualFilters[key];
			let pageFilter = myPageFilters.find(pageFilter => pageFilter.attribute === filter.name);
			if (!pageFilter) {
				delete actualFilters[key];
				// delete filter from local storage
				let filterObject = {
					assistantID: profile.assistantID,
					page: route.route,
					attribute: filter.name
				};
				dispatch(deleteFilter(filterObject));
			}
		});

		// Get charts with filters
		dispatch(
			wsEvent({
				event: "getChartsForPage",
				payload: {
					isSystem: route.system ?? false,
					route: route.route,
					filterByAgent: route?.filterByAgent ?? false,
					wsEventResult: UPDATE_PAGE_CHART,
					filters: actualFilters,
					mandatoryFilter: pageFilters.map(filter => filter.attribute)
				}
			})
		);

		setReloadTable(!reloadTable);
	}

	function setPlaceHolderCharts(charts) {
		setCharts(charts);
		setChartsLoading(false);
	}

	function updatePageChart(chart) {
		setCharts(prevCharts => {
			return prevCharts.map(pChart => {
				if (pChart.code === chart.code) {
					return { ...pChart, data: chart.data, loaded: true, dictionary: chart.dictionary };
				}
				return pChart;
			});
		});
	}
	// Actions button for form pages
	const getFormButtons = () => {
		if (form?.active) {
			return (
				<>
					<MDButton
						style={{ height: "100%", marginRight: "0.75rem" }}
						variant="contained"
						color="info"
						onClick={insertFormItem}
					>
						<Icon>add</Icon>&nbsp;
						{i18n.t("FORMS.LABELS.add") + (form?.pageLabel ?? route.name)}
					</MDButton>
					{form?.importCSV === true && (
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							component="label"
							color="info"
						>
							<input
								type="file"
								name="file"
								accept={[
									".xls",
									".xlsx",
									".xlsm",
									".xlsb",
									".xlt",
									".xltx",
									".xltm",
									".xlam",
									".ods",
									".csv"
								]}
								hidden
								onChange={handleImportFile}
							></input>
							<Icon>publish</Icon>&nbsp;Importer un fichier
						</MDButton>
					)}
				</>
			);
		} else {
			return null;
		}
	};
	// Filters in top bar
	const getFilters = () => {
		if (pageFilters) {
			return (
				<MDBox display="flex" alignItems="center">
					{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
					{route?.filterByAgent ? (
						<Tooltip placement="top" title={i18n.t("SETTINGS.PAGES.filterByAgentPage")}>
							<Icon fontSize="large">person</Icon>
						</Tooltip>
					) : (
						<MDBox></MDBox>
					)}
				</MDBox>
			);
		} else {
			return null;
		}
	};

	const handleEditChart = code => {
		handleEditChartProps(code, (editedChart, edit) => {
			if (edit) {
				const onSuccess = res => {
					dispatch(
						display({
							message: i18n.t("SETTINGS.CHARTS.SUCCESS.edit"),
							type: "success"
						})
					);
					loadCharts(null, editedChart.code);
				};
				dispatch(SettingsActions.editSetting(profile.assistantID, "chart", editedChart, onSuccess));
			}
		});
	};

	/**
	 * Reorder the charts
	 * @param {<string>} type - Types of the charts
	 * @param {number} from - From index
	 * @param {number} to - To index
	 */
	function handleReorderChart(type, from, to) {
		// 1- Get the current charts
		const currentCharts = getChartsData(type);

		// 2- Clone the current charts
		const reorderedCharts = lod_.cloneDeep(currentCharts);
		// 3- Swap the charts
		const [removed] = reorderedCharts.splice(from, 1);
		reorderedCharts.splice(to, 0, removed);

		// 4- Get the codes of the reordered charts
		const reorderedChartsCodes = reorderedCharts.map(chart => chart.code);

		// 5- Set the rank of the reordered charts
		const reorderedChartsWithRank = reorderedCharts.map((chart, index) => {
			// Set the rank
			chart.display.rank = index;
			// Update the chart in the backend
			dispatch(
				SettingsActions.editSetting(
					profile.assistantID,
					"chart",
					{
						code: chart.code,
						"display.rank": index
					},
					() => {}
				)
			);

			return chart;
		});

		// 6- Update the charts
		setCharts(prevCharts => {
			return prevCharts.map(pChart => {
				if (reorderedChartsCodes.includes(pChart.code)) {
					return reorderedChartsWithRank.find(chart => chart.code === pChart.code);
				}
				return pChart;
			});
		});

		// reorder in back
	}

	/**
	 * Drag handler
	 * @param {*} param0
	 * @returns
	 */
	const dragHandler = ({ type, from, to }) => {
		if (from === to) return;
		handleReorderChart(type, from, to);
	};

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setFirstLoad(true);
		setChartsLoading(true);

		async function load() {
			// Set page filters
			let myPageFilters = await getPageFilters();
			setPageFilters(myPageFilters);
			// Load the charts with default filters
			let defaultFilters = buildDefaultFilters(myPageFilters);
			loadCharts(defaultFilters, myPageFilters);
			// Avoid multiples requests
			setFirstLoad(false);
		}

		load();
		/**
		 * WS Event
		 */
		socket.on(UPDATE_PAGE_CHART, updatePageChart);
		socket.on(`${UPDATE_PAGE_CHART}Placeholder`, setPlaceHolderCharts);

		return () => {
			socket.off(UPDATE_PAGE_CHART, updatePageChart);
			socket.off(`${UPDATE_PAGE_CHART}Placeholder`, setPlaceHolderCharts);
		};
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * When filters change, reload charts
	 */
	useEffect(() => {
		if (!firstLoad) {
			loadCharts();
		}
	}, [filters]);
	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else {
		/* Main component */
		return (
			<DashboardLayout>
				{!lod_.isEmpty(dictionary) && (
					<ImportDialog
						{...importDialog}
						dictionary={lod_.get(dictionary, route.form.routeDictionary)}
						handleClose={() => setImportDialog({ ...importDialog, open: false })}
						handleSave={handleImportCSV}
					/>
				)}

				<FloatingButton
					edit={pageEditMode}
					onClick={() => {
						setPageEditMode(!pageEditMode);
					}}
				/>

				<MDBox>
					<DashboardNavbar
						filters={[
							<MDBox>
								{getFormButtons()}
								{getFilters()}
							</MDBox>
						]}
					/>

					<MDBox mt={4}>
						{/* One value */}
						<MDBox display="flex" flexDirection="row" mt={4}>
							{pageEditMode && (
								<ChartEditingOrder
									getChartsData={getChartsData}
									type={["oneValue"]}
									dragHandler={dragHandler}
								/>
							)}
							<MDBox
								flex="5"
								style={{
									width: "100%",
									overflowX: "auto"
								}}
							>
								<Grid mt={0.5} container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
									{getChartsData(["oneValue"]).map((chart, index) => {
										/*  Get the first data object, because it has to be the only one */
										return (
											<OneValueChart
												id={chart.code}
												key={index}
												chart={chart}
												data={chart.data}
												dictionary={chart.dictionary ?? {}}
												xs={2}
												md={2}
												lg={2}
												xxl={chart.display?.width ?? 2}
												handleEditChart={handleEditChart}
												resizeChart={(code, size) => {
													let clonedCharts = lod_.cloneDeep(charts);
													let index = clonedCharts.findIndex(chart => chart.code === code);
													clonedCharts[index].display.width = size;
													setCharts(clonedCharts);
												}}
											/>
										);
									})}
								</Grid>
							</MDBox>
						</MDBox>
						{pageEditMode && <Divider mt={10} mb={10} />}
						{/* Proportion graphs */}
						<MDBox display="flex" flexDirection="row">
							{pageEditMode && (
								<ChartEditingOrder
									getChartsData={getChartsData}
									type={["proportion", "oneDimension", "list"]}
									dragHandler={dragHandler}
								/>
							)}
							<MDBox
								flex="5"
								style={{
									width: "100%",
									overflowX: "auto"
								}}
							>
								<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
									{getChartsData(["proportion", "oneDimension", "list"]).map((chart, index) => {
										return (
											<ProportionChart
												id={chart.code}
												key={index}
												chart={chart}
												data={chart.data}
												mt={5}
												xs={2}
												md={4}
												lg={2}
												xxl={chart.display?.width ?? 3}
											/>
										);
									})}
								</Grid>
							</MDBox>
						</MDBox>
						{pageEditMode && <Divider mt={10} mb={10} />}
						{/* Cross Tables */}
						<MDBox display="flex" flexDirection="row">
							{pageEditMode && (
								<ChartEditingOrder
									getChartsData={getChartsData}
									type={["crossTable", "timeSeries", "multiCollection"]}
									dragHandler={dragHandler}
								/>
							)}
							<MDBox
								flex="5"
								style={{
									width: "100%",
									overflowX: "auto"
								}}
							>
								<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
									{getChartsData(["crossTable", "timeSeries", "multiCollection"]).map(
										(chart, index) => {
											return (
												<CrossTable
													id={chart.code}
													chart={chart}
													data={chart.data}
													key={index}
													mt={5}
													xs={2}
													md={4}
													lg={2}
													xxl={6}
												/>
											);
										}
									)}
								</Grid>
							</MDBox>
						</MDBox>
					</MDBox>
				</MDBox>
				{pageEditMode && <Divider mt={10} mb={10} />}
				{/* Pagined table */}
				<MDBox display="flex" flexDirection="row">
					{pageEditMode && (
						<ChartEditingOrder
							getChartsData={getChartsData}
							type={["paginedList"]}
							dragHandler={dragHandler}
						/>
					)}
					<MDBox
						flex="5"
						style={{
							width: "100%",
							overflowX: "auto"
						}}
					>
						{getChartsData(["paginedList"]).map((chart, index) => {
							if (chart.loaded) {
								return (
									<DefaultDataTable
										reloadTable={reloadTable}
										dictionary={chart.dictionary}
										id={chart.code}
										form={form}
										list={chart}
										pagination={chart.pagination}
										canSearch
										key={index}
										table={chart.data}
										display={chart.request.attributesDisplay}
										filters={getLocalStorageBackValues(
											profile.selectedAssistant.assistantID,
											route.route,
											filters
										)}
										actionEditHandle={items => updateFormItem(items, chart.request.collection)}
										actionDeleteHandle={items => deleteFormItem(items, chart.request.collection)}
									/>
								);
							} else {
								return <Skeleton height={500} />;
							}
						})}
					</MDBox>
				</MDBox>
				{/* Rules */}
				{getChartsData(["rule"]).map((chart, index) => {
					let rule = chart.data;
					return (
						<RuleComponent
							key={index}
							rule={rule}
							options={chart.options}
							dictionary={chart.dictionary}
							outputDictionary={chart.outputDictionary}
						/>
					);
				})}
				{/* Dictionaries */}
				{getChartsData(["dictionary"]).map((chart, index) => {
					return (
						<MDBox mb={2} key={index}>
							<DictionaryDataTable
								draggable
								display={chart.request.attributesDisplay}
								dictionary={chart?.data?.dictionary}
								title={chart.display?.title}
								dictionaryCode={chart.code}
								actions={[
									<Tooltip placement="top" title={i18n.t("SETTINGS.edit")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												updateFormItem(object, chart.request.collection);
											}}
										>
											<Icon fontSize="medium">edit</Icon>
										</IconButton>
									</Tooltip>,
									<Tooltip placement="top" title={i18n.t("SETTINGS.delete")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												deleteFormItem(object, chart.request.collection);
											}}
										>
											<Icon fontSize="medium">delete</Icon>
										</IconButton>
									</Tooltip>
								]}
								dictionaryFormat={chart.dictionary?.dictionary}
								handleAddRow={datas => {
									// dictionaryAddDatas(datas);
								}}
							/>
						</MDBox>
					);
				})}
			</DashboardLayout>
		);
	}
}
